<template>
    <v-container fluid>
        <page-banner links="Ma formation/Cadrage"></page-banner>
        <formation-header></formation-header>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <span class="text-h5">Cadrage de la formation</span>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row>
                    <template v-for="(state, identifier) in formationStateList" >
                        <v-chip :outlined="identifier !== formation.status" :color="state.color" :key="state.name" @click="updateFormation('status', identifier)">{{ state.name }}</v-chip>
                        <v-spacer :key="state.name + '_spacer'"></v-spacer>
                    </template>
                </v-row>
            </v-col>
            <v-col offset="1">
                <v-row>
                    <v-chip
                        :outlined="!formation.shared"
                        color="#CAB397"
                        :disabled="formation.owner !== user.id"
                        @click="updateFormation('shared', !formation.shared)"
                    >
                        {{ formation.shared ? "Partagée" : "Partager" }}
                    </v-chip>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mt-15">
            <v-card width="100%" class="blue darken-1 pa-0" tile elevation="0" height="32px">
                <v-card-title class="text-h5 pa-0"><v-icon class="mx-5" color="purple">fas fa-sort-amount-down-alt</v-icon>Description du projet de formation</v-card-title>
            </v-card>
        </v-row>

        <v-row class="mt-10">
            <span class="text-h4 text-uppercase">Objectifs du projet</span>
        </v-row>

        <v-row>
            <span class="text-h6">Objectifs généraux du projet</span>
        </v-row>

        <v-row>
            <custom-input
                type="formation"
                :object-id="formation.id"
                value-name="description"
                long-input elevation="1"
                placeholder="Décrivez ici les objectifs généraux de votre projet de formation, c'est-à-dire la traduction des objectifs stratégiques en objectifs pédagogiques"
            ></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
            <span class="text-h6">Objectifs pédagogiques</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="goal" long-input elevation="1" placeholder="Décrivez ici les compétences principales que vont développer les apprenants lors de la formation"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Public cible</span>
        </v-row>

        <v-row>
            <span class="text-h6">Caractéristiques du public cible</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="target" long-input elevation="1" placeholder="Décrivez ici le type de public, par exemple tel que vous l'imaginez à la suite d'une étude avec la carte d'empathie"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Stratégie pédagogique</span>
        </v-row>

        <v-row>
            <span class="text-h6">Principes</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="principles" long-input elevation="1" placeholder="Décrivez ici une stratégie pédagogique adaptée aux différentes contraintes, notamment celles de votre public cible"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
            <span class="text-h6">Système d'évaluation</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="evaluation" long-input elevation="1" placeholder="Décrivez ici les différents types d'évaluations envisagées afin qu'elles soient alignées avec vos objectifs pédagogiques"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Durée maximale de la formation</span>
        </v-row>

        <v-row>
            <span class="text-h6">Durée de la formation pour le participant</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="maxDuration" long-input elevation="1" placeholder="Décrivez ici la durée maximale envisagée et ses raisons"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Stratégie technique</span>
        </v-row>

        <v-row>
            <span class="text-h6">Mode de diffusion</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="distributionMethod" long-input elevation="1" placeholder="Décrivez ici la ou les manière(s) que vous envisagez d'utiliser pour diffuser votre formation"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
            <span class="text-h6">Caractéristiques des postes clients</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="clientWorkstation" long-input elevation="1" placeholder="Décrivez ici les moyens qu'utiliseront les apprenants pour suivre la formation, ordinateur, tablette, smartphone..."></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
            <span class="text-h6">Outils de production retenus</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="productionTool" long-input elevation="1" placeholder="Décrivez ici quels sont les outils de productions des ressources qui ont été retenus"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Impact sur l'organisation</span>
        </v-row>

        <v-row>
            <span class="text-h6">Gestion des flux d'inscription</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="registration" long-input elevation="1" placeholder="Décrivez ici l'impact des modalités d'inscription, notamment en ligne, sur l'administration de l'organisation (ex: plus de travail ? Inscription plus rapide ?)"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row>
            <span class="text-h6">Les formateurs</span>
        </v-row>

        <v-row>
            <custom-input type="formation" :object-id="formation.id" value-name="trainers" long-input elevation="1" placeholder="Décrivez ici le rôle joué par les formateurs, sont-ils amenés à concevoir ? Quels sont les changements que cela implique pour eux ?"></custom-input>
        </v-row>

        <v-divider class="my-10"></v-divider>

        <v-row class="mt-5">
            <span class="text-h4 text-uppercase">Organisation du projet</span>
        </v-row>

        <v-row>
            <span class="text-h6">Pilotage du projet</span>
        </v-row>

        <v-row class="mb-15">
            <custom-input type="formation" :object-id="formation.id" value-name="steering" long-input elevation="1" placeholder="Décrivez ici qui dirige le projet ? Quels sont les membres de l'équipe ? Quel sera leur niveau d'implication ?"></custom-input>
        </v-row>


        <next-page-button></next-page-button>

    </v-container>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import FormationHeader from "@/components/designer/FormationHeader";
import NextPageButton from "@/components/NextPageButton";
import { mapActions, mapGetters, mapState } from "vuex";
import CustomInput from "../../../components/CustomInput";

export default {
    name: "Scopping",
    components: { CustomInput, NextPageButton, FormationHeader, PageBanner },

    computed: {
        ...mapState("settings", ["user"]),
        ...mapState("formations", ["currentFormation"]),
        ...mapState("global", ["formationStateList"]),

        formation: function() {
            return this.getFormationById()(this.currentFormation);
        }
    },

    methods: {
        ...mapActions("formations", ["modifyFormation"]),

        ...mapGetters("formations", ["getFormationById"]),

        updateFormation: function(element, value) {
            this.modifyFormation({
                formationId: this.currentFormation,
                [element]: value,
            })
        }
    },
}
</script>

<style scoped>

</style>